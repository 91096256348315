import Text from '@components/Text';

import { Props } from './types';

import { StyledButton } from './styles';

const Button = ({
  text,
  fluid,
  selected,
  type = 'primary',
  ...rest
}: Props) => {
  return (
    <StyledButton fluid={fluid} selected={selected} type={type} {...rest}>
      <Text weight="bold">{text}</Text>
    </StyledButton>
  );
};

export default Button;
